<template>
    <!-- 这里之所以添加preventDefault，是因为浏览器在拖拽的时候页面会左右上下滑动 -->
    <section
        @touchmove="
            (e) => {
                e.stopPropagation();
                if (!createdImgShow) {
                    e.preventDefault();
                }
            }
        "
    >
        <Container
            v-if="data && data.length > 0"
            :data="data"
            @showPreviewImg="show"
            @hidePreviewImg="hide"
            @updatePageSize="
                ({ pageSize, appendSpaceNum }) =>
                    onLoad({ pageSize, appendSpaceNum })
            "
        ></Container>
        <!-- 保存和分享的时候图片预览 -->
        <div v-if="createdImgShow" class="img-for-download">
            <img id="imgForDownload" style="width: 100%; display: block" />
            <div class="save-share">长按图片，可以保存分享</div>
        </div>
    </section>
</template>

<script>
import Container from "./Container";
export default {
    components: {
        Container,
    },
    data() {
        return {
            createdImgShow: false,
            // pageSize: 30,
            pageSize: sessionStorage.getItem("pageSize") ? Number(sessionStorage.getItem("pageSize")) : 30,
            data: [
                {
                    lotteryId: "1550457694086852608",
                    lotteryKind: 7,
                    periodText: "2764期",
                    lotteryContent: "39254112",
                    lotteryDate: "2022-07-22T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 19,
                    num1: 3,
                    num2: 9,
                    num3: 2,
                    num4: 5,
                    num5: 4,
                    num6: 1,
                    num7: 12,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1551182461251145728",
                    lotteryKind: 7,
                    periodText: "2765期",
                    lotteryContent: "94289211",
                    lotteryDate: "2022-07-24T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 23,
                    num1: 9,
                    num2: 4,
                    num3: 2,
                    num4: 8,
                    num5: 9,
                    num6: 2,
                    num7: 11,
                    groupStartLabel: 1,
                },
                {
                    lotteryId: "1551907258541068288",
                    lotteryKind: 7,
                    periodText: "2766期",
                    lotteryContent: "5677870",
                    lotteryDate: "2022-07-26T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 25,
                    num1: 5,
                    num2: 6,
                    num3: 7,
                    num4: 7,
                    num5: 8,
                    num6: 7,
                    num7: 0,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1552994390770806784",
                    lotteryKind: 7,
                    periodText: "2767期",
                    lotteryContent: "4649632",
                    lotteryDate: "2022-07-29T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 23,
                    num1: 4,
                    num2: 6,
                    num3: 4,
                    num4: 9,
                    num5: 6,
                    num6: 3,
                    num7: 2,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1553719226675548160",
                    lotteryKind: 7,
                    periodText: "2768期",
                    lotteryContent: "78073913",
                    lotteryDate: "2022-07-31T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 22,
                    num1: 7,
                    num2: 8,
                    num3: 0,
                    num4: 7,
                    num5: 3,
                    num6: 9,
                    num7: 13,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1554443966314274816",
                    lotteryKind: 7,
                    periodText: "2769期",
                    lotteryContent: "6962646",
                    lotteryDate: "2022-08-02T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 23,
                    num1: 6,
                    num2: 9,
                    num3: 6,
                    num4: 2,
                    num5: 6,
                    num6: 4,
                    num7: 6,
                    groupStartLabel: 1,
                },
                {
                    lotteryId: "1555531179011723264",
                    lotteryKind: 7,
                    periodText: "2770期",
                    lotteryContent: "98773113",
                    lotteryDate: "2022-08-05T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 31,
                    num1: 9,
                    num2: 8,
                    num3: 7,
                    num4: 7,
                    num5: 3,
                    num6: 1,
                    num7: 13,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1556255915819782144",
                    lotteryKind: 7,
                    periodText: "2771期",
                    lotteryContent: "4242624",
                    lotteryDate: "2022-08-07T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 12,
                    num1: 4,
                    num2: 2,
                    num3: 4,
                    num4: 2,
                    num5: 6,
                    num6: 2,
                    num7: 4,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1556980810102984704",
                    lotteryKind: 7,
                    periodText: "2772期",
                    lotteryContent: "1792079",
                    lotteryDate: "2022-08-09T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 19,
                    num1: 1,
                    num2: 7,
                    num3: 9,
                    num4: 2,
                    num5: 0,
                    num6: 7,
                    num7: 9,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1558067846188748800",
                    lotteryKind: 7,
                    periodText: "2773期",
                    lotteryContent: "8783754",
                    lotteryDate: "2022-08-12T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 26,
                    num1: 8,
                    num2: 7,
                    num3: 8,
                    num4: 3,
                    num5: 7,
                    num6: 5,
                    num7: 4,
                    groupStartLabel: 1,
                },
                {
                    lotteryId: "1558792640976740352",
                    lotteryKind: 7,
                    periodText: "2774期",
                    lotteryContent: "4177740",
                    lotteryDate: "2022-08-14T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 19,
                    num1: 4,
                    num2: 1,
                    num3: 7,
                    num4: 7,
                    num5: 7,
                    num6: 4,
                    num7: 0,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1559517449060732928",
                    lotteryKind: 7,
                    periodText: "2775期",
                    lotteryContent: "41633114",
                    lotteryDate: "2022-08-16T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 14,
                    num1: 4,
                    num2: 1,
                    num3: 6,
                    num4: 3,
                    num5: 3,
                    num6: 1,
                    num7: 14,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1560604564424544256",
                    lotteryKind: 7,
                    periodText: "2776期",
                    lotteryContent: "35701614",
                    lotteryDate: "2022-08-19T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 15,
                    num1: 3,
                    num2: 5,
                    num3: 7,
                    num4: 0,
                    num5: 1,
                    num6: 6,
                    num7: 14,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1561329308389969920",
                    lotteryKind: 7,
                    periodText: "2777期",
                    lotteryContent: "17870610",
                    lotteryDate: "2022-08-21T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 23,
                    num1: 1,
                    num2: 7,
                    num3: 8,
                    num4: 7,
                    num5: 0,
                    num6: 6,
                    num7: 10,
                    groupStartLabel: 1,
                },
                {
                    lotteryId: "1562054129709060096",
                    lotteryKind: 7,
                    periodText: "2778期",
                    lotteryContent: "51056211",
                    lotteryDate: "2022-08-23T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 11,
                    num1: 5,
                    num2: 1,
                    num3: 0,
                    num4: 5,
                    num5: 6,
                    num6: 2,
                    num7: 11,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1563141290794815488",
                    lotteryKind: 7,
                    periodText: "2779期",
                    lotteryContent: "16854412",
                    lotteryDate: "2022-08-26T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 20,
                    num1: 1,
                    num2: 6,
                    num3: 8,
                    num4: 5,
                    num5: 4,
                    num6: 4,
                    num7: 12,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1563866079595941888",
                    lotteryKind: 7,
                    periodText: "2780期",
                    lotteryContent: "97942010",
                    lotteryDate: "2022-08-28T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 29,
                    num1: 9,
                    num2: 7,
                    num3: 9,
                    num4: 4,
                    num5: 2,
                    num6: 0,
                    num7: 10,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1564590879905271808",
                    lotteryKind: 7,
                    periodText: "2781期",
                    lotteryContent: "0742566",
                    lotteryDate: "2022-08-30T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 13,
                    num1: 0,
                    num2: 7,
                    num3: 4,
                    num4: 2,
                    num5: 5,
                    num6: 6,
                    num7: 6,
                    groupStartLabel: 1,
                },
                {
                    lotteryId: "1565678044701507584",
                    lotteryKind: 7,
                    periodText: "2782期",
                    lotteryContent: "37769011",
                    lotteryDate: "2022-09-02T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 23,
                    num1: 3,
                    num2: 7,
                    num3: 7,
                    num4: 6,
                    num5: 9,
                    num6: 0,
                    num7: 11,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1566402828756705280",
                    lotteryKind: 7,
                    periodText: "2783期",
                    lotteryContent: "7257059",
                    lotteryDate: "2022-09-04T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 21,
                    num1: 7,
                    num2: 2,
                    num3: 5,
                    num4: 7,
                    num5: 0,
                    num6: 5,
                    num7: 9,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1567127575035502592",
                    lotteryKind: 7,
                    periodText: "2784期",
                    lotteryContent: "20202312",
                    lotteryDate: "2022-09-06T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 4,
                    num1: 2,
                    num2: 0,
                    num3: 2,
                    num4: 0,
                    num5: 2,
                    num6: 3,
                    num7: 12,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1568214726260801536",
                    lotteryKind: 7,
                    periodText: "2785期",
                    lotteryContent: "3950162",
                    lotteryDate: "2022-09-09T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 17,
                    num1: 3,
                    num2: 9,
                    num3: 5,
                    num4: 0,
                    num5: 1,
                    num6: 6,
                    num7: 2,
                    groupStartLabel: 1,
                },
                {
                    lotteryId: "1568939493993463808",
                    lotteryKind: 7,
                    periodText: "2786期",
                    lotteryContent: "4234946",
                    lotteryDate: "2022-09-11T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 13,
                    num1: 4,
                    num2: 2,
                    num3: 3,
                    num4: 4,
                    num5: 9,
                    num6: 4,
                    num7: 6,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1569664282225807360",
                    lotteryKind: 7,
                    periodText: "2787期",
                    lotteryContent: "5800793",
                    lotteryDate: "2022-09-13T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 13,
                    num1: 5,
                    num2: 8,
                    num3: 0,
                    num4: 0,
                    num5: 7,
                    num6: 9,
                    num7: 3,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1570751476067733504",
                    lotteryKind: 7,
                    periodText: "2788期",
                    lotteryContent: "9631475",
                    lotteryDate: "2022-09-16T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 19,
                    num1: 9,
                    num2: 6,
                    num3: 3,
                    num4: 1,
                    num5: 4,
                    num6: 7,
                    num7: 5,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1571476178625105920",
                    lotteryKind: 7,
                    periodText: "2789期",
                    lotteryContent: "28577210",
                    lotteryDate: "2022-09-18T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 22,
                    num1: 2,
                    num2: 8,
                    num3: 5,
                    num4: 7,
                    num5: 7,
                    num6: 2,
                    num7: 10,
                    groupStartLabel: 1,
                },
                {
                    lotteryId: "1572201010316709888",
                    lotteryKind: 7,
                    periodText: "2790期",
                    lotteryContent: "94806814",
                    lotteryDate: "2022-09-20T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 21,
                    num1: 9,
                    num2: 4,
                    num3: 8,
                    num4: 0,
                    num5: 6,
                    num6: 8,
                    num7: 14,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1573288158200295424",
                    lotteryKind: 7,
                    periodText: "2791期",
                    lotteryContent: "47710812",
                    lotteryDate: "2022-09-23T00:00:00.000+08:00",
                    dayOfWeek: 5,
                    lotteryVu: 19,
                    num1: 4,
                    num2: 7,
                    num3: 7,
                    num4: 1,
                    num5: 0,
                    num6: 8,
                    num7: 12,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1574012902369542144",
                    lotteryKind: 7,
                    periodText: "2792期",
                    lotteryContent: "0505205",
                    lotteryDate: "2022-09-25T00:00:00.000+08:00",
                    dayOfWeek: 7,
                    lotteryVu: 10,
                    num1: 0,
                    num2: 5,
                    num3: 0,
                    num4: 5,
                    num5: 2,
                    num6: 0,
                    num7: 5,
                    groupStartLabel: 0,
                },
                {
                    lotteryId: "1574737689047875584",
                    lotteryKind: 7,
                    periodText: "2793期",
                    lotteryContent: "89201111",
                    lotteryDate: "2022-09-27T00:00:00.000+08:00",
                    dayOfWeek: 2,
                    lotteryVu: 19,
                    num1: 8,
                    num2: 9,
                    num3: 2,
                    num4: 0,
                    num5: 1,
                    num6: 1,
                    num7: 11,
                    groupStartLabel: 1,
                },
                {
                    lotteryId: null,
                    lotteryKind: null,
                    periodText: "2794期",
                    lotteryContent: null,
                    lotteryDate: null,
                    dayOfWeek: null,
                    lotteryVu: null,
                    num1: null,
                    num2: null,
                    num3: null,
                    num4: null,
                    num5: null,
                    num6: null,
                    num7: null,
                    groupStartLabel: null,
                },
                {
                    lotteryId: null,
                    lotteryKind: null,
                    periodText: "2795期",
                    lotteryContent: null,
                    lotteryDate: null,
                    dayOfWeek: null,
                    lotteryVu: null,
                    num1: null,
                    num2: null,
                    num3: null,
                    num4: null,
                    num5: null,
                    num6: null,
                    num7: null,
                    groupStartLabel: null,
                },
                {
                    lotteryId: null,
                    lotteryKind: null,
                    periodText: "2796期",
                    lotteryContent: null,
                    lotteryDate: null,
                    dayOfWeek: null,
                    lotteryVu: null,
                    num1: null,
                    num2: null,
                    num3: null,
                    num4: null,
                    num5: null,
                    num6: null,
                    num7: null,
                    groupStartLabel: null,
                },
                {
                    lotteryId: null,
                    lotteryKind: null,
                    periodText: "2797期",
                    lotteryContent: null,
                    lotteryDate: null,
                    dayOfWeek: null,
                    lotteryVu: null,
                    num1: null,
                    num2: null,
                    num3: null,
                    num4: null,
                    num5: null,
                    num6: null,
                    num7: null,
                    groupStartLabel: null,
                },
            ],
        };
    },
    methods: {
        show() {
            this.createdImgShow = true;
        },
        hide() {
            this.createdImgShow = false;
        },
        onLoad({ pageSize = 30, appendSpaceNum = 4 }) {
            this.data = [];
            this.$nextTick(async () => {
                const res = await this.$HTTP.post(
                    this,
                    "API_LOTTERY_LISTS_SPACE",
                    {
                        lotteryKind: this.$store.state.common.lotteryKind,
                        pageNo: "1",
                        pageSize, //"30",
                        appendSpaceNum: appendSpaceNum,
                    },
                    {},
                    true
                );
                this.data = res.data; //.reverse();
              console.log("数据",res);
            });
        },
        // updatePageSize(pageSize) {
        //     this.pageSize = pageSize;
        //     this.$nextTick(() => {
        //         this.onLoad({});
        //     });
        // },
    },
    created() {
        this.onLoad({pageSize: this.pageSize});
    },
};
</script>

<style lang="less" scoped>
.img-for-download {
    position: fixed;
    left: 0;
    top: 40px;
    z-index: 299;
    width: 100%;
    height: 100vh;
    overflow: auto;
}
.save-share {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: 2;
}
</style>