var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{on:{"touchmove":(e) => {
            e.stopPropagation();
            if (!_vm.createdImgShow) {
                e.preventDefault();
            }
        }}},[(_vm.data && _vm.data.length > 0)?_c('Container',{attrs:{"data":_vm.data},on:{"showPreviewImg":_vm.show,"hidePreviewImg":_vm.hide,"updatePageSize":({ pageSize, appendSpaceNum }) =>
                _vm.onLoad({ pageSize, appendSpaceNum })}}):_vm._e(),(_vm.createdImgShow)?_c('div',{staticClass:"img-for-download"},[_c('img',{staticStyle:{"width":"100%","display":"block"},attrs:{"id":"imgForDownload"}}),_c('div',{staticClass:"save-share"},[_vm._v("长按图片，可以保存分享")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }